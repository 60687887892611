.card .card-title {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
}

/* Desktop */
.desktop.d-xl-block .card .card-title {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
}

.desktop.d-lg-block .card .card-title {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
}

.desktop.d-md-block .card .card-title {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}

.desktop.d-sm-block .card .card-title {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}

.desktop.d-sm-none .card .card-title {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
}

/* Tablet */

.tablet.d-md-block .card .card-title {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}

.tablet.d-lg-block .card .card-title {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
}

/* Mobile */
.mobile.d-md-block .card .card-title {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}

.mobile.d-sm-block .card .card-title {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}

.mobile.d-sm-none .card .card-title {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}

.card {
    background-color: rgb(119, 113, 113);
}
.card:hover {
    background-color: #ffda00;
}

.card:hover .card-title {
    color: black !important;
}