.list-group {
  width: 300%;
  max-width: 1000px;
  /* margin-inline: 1.5rem; */
}

.list-group-item {
  cursor: pointer;
  width: 120px;
}

.list-group-item-action:hover,
.list-group .active:hover {
  background-color: #ffda00;
  color: black;
}

.list-group .rounded {
    border-color: #ffda00;
}

.list-group .active {
    background-color: rgb(119, 113, 113);
  }

