.btn-outline-success {
    border-color: #ffda00; 
    color: #ffda00;
}

.btn-outline-success:hover {
    background-color: #ffda00 !important; 
    color: black !important; 
} 

.btn-outline-success:active {
    background-color: rgb(88, 0, 0) !important; 
    color: #ffda00 !important; 
} 


.nav-item .nav-link.active {
    color: #ffda00;
}