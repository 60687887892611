.apc.desktop.d-xl-block .card {
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.apc.desktop.d-xl-block .header-body {
  background-color: rgb(88, 0, 0);
  height: 75px;
}

.apc.desktop.d-xl-block .card-img-top {
  width: 300px;
  height: 300px;
  display: block;
  margin: 0 auto;
  border-radius: 50%;
  border: 5px solid #ffda00;
  object-fit: cover;
}

.apc.desktop.d-xl-block .card-body {
  background-color: rgb(119, 113, 113);

  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically if needed */
  text-align: center; /* Center text horizontally */
  padding: 20px; /* Optional padding for spacing */
}

.apc.desktop.d-xl-block .card .card-title {
  color: rgb(255, 255, 255);
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  font-weight: 600;
  letter-spacing: 1px;
}

.apc.card:hover .card-title {
  color: white !important;
}

.rating-container {
    display: flex;
    justify-content: center; /* Center rating stars horizontally within the container */
}
