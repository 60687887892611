.page-link {
    background: rgb(119, 113, 113);
    color: #ffda00;
}

.pagination .page-link:hover {
    background-color: rgb(88, 0, 0);
    color: #ffda00;

}

.pagination .page-item.active .page-link {
    background-color: #ffda00;
    color: black;
}

.pagination .page-item.active .page-link:hover {
    background-color: rgb(88, 0, 0);
    color: #ffda00;

}

.pagination .page-link {
    border-color: black;
}

