@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
body {
  background: black;
  width: 100%;
  height: 100vh;
}

body .profile_card {
  width: 1200px;
  height: 420px;
  background:  rgb(119, 113, 113);
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  top: 0%;
  bottom: 0%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  /* box-shadow: 0px 20px 30px 3px rgba(0, 0, 0, 0.55); */
  display: flex;
  flex-direction: row;
}

body .card_left {
  width: 60%;
  height: 400px;
  overflow: hidden;
  background: transparent;
  /* display: flex; */
  /* align-items: center; */
  /* margin-left: 1%; */
  margin: 30px 0px 0px 30px;
}
body .card_left img {
  width: 100%;
  height: auto;
  border-radius: 10px 0 0 10px;
  -webkit-border-radius: 10px 0 0 10px;
  -moz-border-radius: 10px 0 0 10px;
  position: relative;
  
}
body .card_right {
  width: 60%;
  background: rgb(119, 113, 113); /*black*/
  height: 400px;
  border-radius: 0 10px 10px 0;
  -webkit-border-radius: 0 10px 10px 0;
  -moz-border-radius: 0 10px 10px 0;
  /* margin-left: 10%; Add margin to create a gap */
}

body .card_right h1 {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-align: left;
  font-size: 40px;
  margin: 30px 0 0 0;
  padding: 0 0 0 40px;
  letter-spacing: 1px;
}
body .card_right__details ul {
  list-style-type: none;
  padding: 0 0 0 40px;
  margin: 10px 0 0 0;
}
body .card_right__details ul li {
  display: inline;
  color: #e3e3e3;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 0 40px 0 0;
}
body .card_right__review p {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  padding: 0 40px 0 40px;
  letter-spacing: 1px;
  margin: 10px 0 10px 0;
  line-height: 20px;
}
body .card_right__review a {
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 0 0 0 40px;
  color: #ffda00;
  margin: 0;
}
body .card_right__button {
  padding: 0 0 0 40px;
  margin: 30px 0 0 0;
}
body .card_right__button button {
  color: #ffda00;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  border: 2px solid #ffda00;
  padding: 10px 10px 10px 20px;
  font-size: 12px;
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/343086/COMdoWZ.png);
  background-size: 12px 12px;
  background-repeat: no-repeat;
  background-position: 7% 50%;
  border-radius: 5px;
  transition-property: all;
  transition-duration: 0.5s;
}
body .card_right__button button:hover {
  color: #000000;
  background-color: #ffda00;
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/343086/rFQ5dHA.png);
  background-size: 12px 12px;
  background-repeat: no-repeat;
  background-position: 10% 50%;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.5s;
}

/* Desktop */

.desktop-mp.d-xl-block .profile_card {
  width: 1000px;
  height: 500px;
  background: rgb(119, 113, 113);
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  top: 0%;
  bottom: 0%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  /* box-shadow: 0px 20px 30px 3px rgba(0, 0, 0, 0.55); */
  display: flex;
  flex-direction: row;
}

.desktop-mp.d-lg-block .profile_card {
  width: 800px;
  height: 500px;
  background: rgb(119, 113, 113);
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  top: 0%;
  bottom: 0%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  /* box-shadow: 0px 20px 30px 3px rgba(0, 0, 0, 0.55); */
  display: flex;
  flex-direction: row;
}

.desktop-mp.d-lg-block .card_right h1 {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-align: left;
  font-size: 30px;
  margin: 30px 0 0 0;
  padding: 0 0 0 40px;
  letter-spacing: 1px;
}

.desktop-mp.d-md-block .card .card-body {
  width: 500px;
  height: 450px;
  background: rgb(119, 113, 113);
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  top: 0%;
  bottom: 0%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}

.desktop-mp.d-md-block .card .card-body h1 {
  color: red;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-align: left;
  font-size: 40px;
  margin: 30px 0 0 0;
  padding: 0 0 0 40px;
  letter-spacing: 1px;
}

.desktop-mp.d-sm-block .card .card-body {
  width: 375px;
  height: 450px;
  background: rgb(119, 113, 113);
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  top: 0%;
  bottom: 0%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}

/* .desktop-mp.d-md-block .card .card-img-top {
 
} */


.desktop-mp.d-sm-block .card .card-body h1 {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-align: left;
  font-size: 30px;
  margin: 30px 0 0 0;
  padding: 0 0 0 40px;
  letter-spacing: 1px;
}


