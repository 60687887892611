/* Arrows */
.slick-prev{

  width: 10px;
  height: 10px;
  padding: 0;
  left: -40px;
  
}


.slick-next {

  width: 10px;
  height: 10px;
  padding: 0;
}

.home.container {
    max-width: 1850px;
}