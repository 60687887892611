Desktop

.desktop-mp.d-xl-block .profile_card  .card_right__rating__stars {
  position: relative;
  right: 310px;
  margin: 10px 0 10px 0;
}

.desktop-mp.d-lg-block .profile_card  .card_right__rating__stars {
  position: relative;
  right: 210px;
  margin: 10px 0 10px 0;
}

.desktop-mp.d-md-block .card  .card_right__rating__stars {
  position: relative;
  right: 295px;
  margin: 10px 0 10px 0;
}

.desktop-mp.d-sm-block .card  .card_right__rating__stars {
  position: relative;
  right: 170px;
  margin: 10px 0 10px 0;
}

/* Mobile */
.d-md-block .card .card_right__rating__stars {
  position: relative;
  right: 370px;
  margin: 10px 0 10px 0;
}

/* body .card_right__rating__stars {
  position: relative;
  right: 410px;
  margin: 10px 0 10px 0;
} */


body .card_right__rating__stars fieldset,
body .card_right__rating__stars label {
  margin: 0;
  padding: 0;
}
body .card_right__rating__stars .rating {
  border: none;
}
body .card_right__rating__stars .rating > input {
  display: none;
}
body .card_right__rating__stars .rating > label:before {
  margin: 5px;
  font-size: 1.25em;
  display: inline-block;
  content: "";
  font-family: FontAwesome;
}
body .card_right__rating__stars .rating > .half:before {
  content: "";
  position: absolute;
}
body .card_right__rating__stars .rating > label {
  color: #ddd;
  float: right;
}
body .card_right__rating__stars .rating > input:checked ~ label,
body .card_right__rating__stars .rating:not(:checked) > label:hover,
body .card_right__rating__stars .rating:not(:checked) > label:hover ~ label {
  color: #ffd700;
}
body .card_right__rating__stars .rating > input:checked + label:hover,
body .card_right__rating__stars .rating > input:checked ~ label:hover,
body .card_right__rating__stars .rating > label:hover ~ input:checked ~ label,
body .card_right__rating__stars .rating > input:checked ~ label:hover ~ label {
  color: #ffed85;
}
